<template>
  <div id="user-list">
    

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Team Members
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="userListTable"
        :loading="loading"
        :options.sync="options"
        :footer-props="{
            'items-per-page-options':[options.itemsPerPage],
            'disable-items-per-page': true,
        }"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="stringToHslColor(item.name)"
              size="32"
            >
              <span
                class="font-weight-medium  white--text"
              >{{ avatarText(item.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'user-view', params : { email: item.email } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"

              >
                {{ item.name }}
            </router-link>
              <small>@{{ item.office_location }}</small>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.role.name)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role.name)}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveUserRoleVariant(item.role.name)"
              >
                {{ resolveUserRoleIcon(item.role.name) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.role.name }}</span>
          </div>
        </template>

        <!-- plan -->
        <template #[`item.title`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.job_title }}</span>
        </template>

        
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
} from '@mdi/js'

import store from '@/store'
import { onUnmounted, ref, onMounted } from '@vue/composition-api'

import { stringToHslColor} from '@core/utils'

// sidebar
import { avatarText } from '@core/utils/filter'
import userStoreModule from '../userStoreModule'

import useUsersList from './useUsersList'

export default {
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })


    const {
      userListTable,
      tableColumns,
      loading,
      options,
      userTotalLocal,
      selectedRows,

      fetchUsers,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserTotalIcon,
    } = useUsersList()

    
    onMounted(() => {
    // const user = JSON.parse(localStorage.getItem('user'))
    //   router.replace({
    //     params: {
    //       email: user.email
    //     }
    //   }).catch(err => {})
      fetchUsers()
    })

    return {
      userListTable,
      tableColumns,
      loading,
      options,
      userTotalLocal,
      selectedRows,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserTotalIcon,
      fetchUsers,
      stringToHslColor,
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
