var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Team Members ")]),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.userListTable,"loading":_vm.loading,"options":_vm.options,"footer-props":{
          'items-per-page-options':[_vm.options.itemsPerPage],
          'disable-items-per-page': true,
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{attrs:{"color":_vm.stringToHslColor(item.name),"size":"32"}},[_c('span',{staticClass:"font-weight-medium  white--text"},[_vm._v(_vm._s(_vm.avatarText(item.name)))])]),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'user-view', params : { email: item.email } }}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('small',[_vm._v("@"+_vm._s(item.office_location))])],1)],1)]}},{key:"item.role",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveUserRoleVariant(item.role.name)) + "--text me-3"),attrs:{"size":"30","color":_vm.resolveUserRoleVariant(item.role.name)}},[_c('v-icon',{attrs:{"size":"18","color":_vm.resolveUserRoleVariant(item.role.name)}},[_vm._v(" "+_vm._s(_vm.resolveUserRoleIcon(item.role.name))+" ")])],1),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.role.name))])],1)]}},{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.job_title))])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }